// 员工修改
import Q from "q";
// 修改员工接口
import web_userBuyer_updateBuyerEmployee from "@/lib/data-service/default/web_userBuyer_updateBuyerEmployee";
// 获取权限列表
import web_common_source_getPermissions from "@/lib/data-service/default/web_common_source_getPermissions";
// 获取员工账号信息
import web_common_user_getUserInfo from "@/lib/data-service/default/web_common_user_getUserInfo";
import limits from '../../component/limits'

export default {
  data() {
    const reg = /[0-9a-zA-Z]+$/;
    var validateUserName = (rule, value, callback) => {
      if (value === undefined || value === "") {
        callback(new Error("请输入账号"));
      } else {
        if (value.length < 4) {
          callback(new Error("最少要输入4个字符"));
        } else if (value.length > 16) {
          callback(new Error("最多可输入16个字符"));
        } else if (!reg.test(value)) {
          callback(new Error("仅限使用数字与字母"));
        }
        callback();
      }
    };

    

    var validateVerify1 = (rule, value, callback) => {
      const verifyPassword = this.form.verifyPassword;
      if (value === '' || value === undefined) {
        if (verifyPassword) {
          callback(new Error("请输入密码"));
        } else {
          callback();
        }
      } else {
        if (value.length < 6) {
          callback(new Error("最少输入6个字符"));
        } else if (value.length > 20) {
          callback(new Error("最多可输入20个字符"));
        } else if (this.reg_string(value)) {
          callback(new Error("仅限使用数字与字母"));
        } else {
          callback();
        }
      }
    };

    var validateVerify2 = (rule, value, callback) => {
      const password = this.form.password;
      if (value === '' || value === undefined) {
        if (password) {
          if (value === '' || value === undefined) {
            callback(new Error("请确认密码"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      } else {
        if (value.length < 6) {
          callback(new Error("最少输入6个字符"));
        } else if (value.length > 20) {
          callback(new Error("最多可输入20个字符"));
        } else if (this.reg_string(value)) {
          callback(new Error("仅限使用数字与字母"));
        } else if (value !== password) {
          callback(new Error("两次密码不一致"));
        } else {
          callback();
        }
      }


    };

    return {
      form: {
        id: "",
        userName: "",
        password: "",
        verifyPassword: "",
        realName: "",
        phone: "",
        qq: "",
        eMail: "",
        resourceIds: []
      },
      formRules: {
        userName: [
          // { required: true, validator: validateUserName, trigger: "blur" }
        ],
        password: [
          { validator: validateVerify1, trigger: "blur" },
        ],
        verifyPassword: [
          { validator: validateVerify2, trigger: "blur" },
        ],
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
          { min: 2, message: "最少输入2个字符", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        qq: [
          {
            pattern: /^[1-9]\d{4,10}$/,
            message: "请输入正确的QQ号码",
            trigger: "blur"
          }
        ],
        eMail: [
          {
            pattern: /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g,
            message: "请输入正确的邮箱",
            trigger: "blur"
          }
        ]
      },
      authorityList: [],
      resourceIds: [],
      userType: '',
    };
  },

  methods: {
    req_get_staff_inof(params) {
      const _this = this;
      const _form = this.form;
      Q.when()
        .then(function () {
          return web_common_user_getUserInfo(params);
        })
        .then(function (res) {
          _this.userType = res.userType;
          _form.id = res.id;
          _form.userName = res.userName;
          _form.realName = res.realName;
          _form.phone = res.phone;
          _form.qq = res.qq;
          _form.eMail = res.eMail;
          _this.resourceIds = res.resourceIds
        });
      return this.form
    },
    req_change_staff(params) {
      return Q.when().then(function () {
        return web_userBuyer_updateBuyerEmployee(params);
      });
    },
    req_get_authority_list(params) {
      return Q.when().then(function () {
        return web_common_source_getPermissions(params);
      });
    },
    getAuthoritySrot(authorityList) {
      let arr = []
      Object.values(limits).map((key, index) => {
        authorityList.map(item => {
          if (item.permission === key) {
            arr.push(item)
          }
        })
      })
      const resourceIds = this.resourceIds;
      for (let i = 0; i < arr.length; i++) {
        const parent = arr[i];
        const childList = arr[i].childList;

        const find = resourceIds.indexOf(parent.resourceId);
        if (find === -1) {
          parent.checked = false;
        } else {
          parent.checked = true;
        }

        for (let j = 0; j < childList.length; j++) {
          const child = childList[j];
          const find = resourceIds.indexOf(child.resourceId);
          if (find === -1) {
            child.checked = false;
          } else {
            child.checked = true;
          }
        }
      }

      this.authorityList = arr;
    },
    get_authority_list(params, form) {
      const _this = this;
      Q.when()
        .then(function () {
          return _this.req_get_authority_list();
        })
        .then(function (res) {
          let list = res.permissionList.filter(item => {
            return form.resourceIds.filter(sItem => {
              if (sItem.resourceId === item) {
                return sItem
              }
            })
          })
          let resultList = _this.get_list(list)
          _this.getAuthoritySrot(resultList)
        });
    },
    get_list(authorityList) {
      let oneLevel
      oneLevel = authorityList.filter(item => {
        return item.resourceLevel === '2'
      })
      oneLevel.map(item => {
        item.checked = true
        let arr = authorityList.reduce(function (newArr, sItem) {
          if (sItem.parentId === item.resourceId) {
            sItem.checked = true
            newArr.push(sItem);
          }
          return newArr;
        }, []);
        item.childList = arr
      })
      return oneLevel;
    },
    async initialize(id) {
      let res = await this.req_get_staff_inof({ id });
      this.get_authority_list({ userId: id }, res);
    },
    checkAll_authorityItem(checked, list) {
      checked = !checked
      setCheck(list, !checked)
      function setCheck(list, status) {
        list.map(item => {
          item.checked = status
        })
      }
      this.$forceUpdate()
    },
    commit_change_staff(form) {
      const _this = this;
      this.$refs[form].validate(valid => {
        if (valid) {
          Q.when()
            .then(function () {
              return _this.req_change_staff(_this.form);
            })
            .then(function () {
              _this.$message({
                message: "员工账号修改成功",
                type: "success"
              });
              _this.to_back();
            });
        } else {
          return false;
        }
      });
    },
    check_item(list) {
      let itemList = list.childList
      let statu = itemList.map(item => {
        return item.checked
      })
      statu.indexOf(true) === -1 ? list.checked = false : list.checked = true
    },
    to_back() {
      this.$router.push({
        name: "distributor-tour-back-staff-management"
      });
      document.documentElement.scrollTop = 0;
    },
    reg_string (str) {
      const reg1 = /\d/;
      const reg2 = /[a-zA-Z]/;
      const arr = str.split('');
      for (let i = 0; i < arr.length; i++) {
        const str = arr[i];
        if (!reg1.test(str) && !reg2.test(str)) return true;
      }
    },
  },
  watch: {
    authorityList: {
      handler (val) {
        const form = this.form;
        const resourceIds = [];
        for (let i = 0; i < val.length; i++) {
          const childList = val[i].childList;
          for (let j = 0; j < childList.length; j++) {
            const child = childList[j];
            if (child.checked) {
              const resourceId = `${child.parentId}-${child.resourceId}`;
              resourceIds.push(resourceId);
            }
          }
        }
        form.resourceIds = resourceIds;
      },
      deep: true,
    },
  },
  mounted() { },
  activated() {
    this.initialize(this.$route.query.id);
  }
};
